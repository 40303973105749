import React from "react";
import Footer from "../components/landing/Footer";
import TopNavBar from "../components/nav/TopNavBar";

const PrivacyPolicy: React.FC = () => {
  return (
    <>
      <TopNavBar />
      <div className="w-full relative bg-white overflow-hidden flex flex-col items-start justify-start leading-[normal] tracking-[normal] p-6">
        <h1 className="text-2xl font-bold mb-4">Privacy Policy</h1>
        <p className="mb-4">Last updated: 2025/01/28</p>

        <p className="mb-4">
          Thank you for choosing to use our Application (the “App”). This
          Privacy Policy explains how we collect, use, disclose, and safeguard
          your information when you use the App. Please read this Privacy Policy
          carefully. If you do not agree with the terms of this Privacy Policy,
          please do not access the App.
        </p>

        <h2 className="text-xl font-semibold mb-2">1. Data We Collect</h2>
        <p className="mb-4">
          We may collect and process the following categories of personal data:
        </p>

        <h3 className="text-lg font-semibold mb-1">1.1 Contact Info</h3>
        <ul className="list-disc pl-5 mb-4">
          <li>
            <strong>Name</strong> (Used for Product Personalization, App
            Functionality)
          </li>
          <li>
            <strong>Email Address</strong> (Used for App Functionality and
            Product Personalization; Linked to your identity; Used for tracking
            purposes)
          </li>
        </ul>

        <h3 className="text-lg font-semibold mb-1">1.2 User Content</h3>
        <ul className="list-disc pl-5 mb-4">
          <li>
            <strong>Photos or Videos</strong> (Used for Product Personalization,
            App Functionality, Analytics; Linked to your identity)
          </li>
          <li>
            <strong>Audio Data</strong> (Used for Product Personalization,
            Analytics, App Functionality; Linked to your identity)
          </li>
        </ul>

        <h3 className="text-lg font-semibold mb-1">1.3 Identifiers</h3>
        <ul className="list-disc pl-5 mb-4">
          <li>
            <strong>User ID</strong> (Used for Developer’s Advertising or
            Marketing; Linked to your identity; Used for tracking purposes)
          </li>
          <li>
            <strong>Device ID</strong> (Used for App Functionality, Analytics)
          </li>
        </ul>

        <h3 className="text-lg font-semibold mb-1">1.4 Purchases</h3>
        <ul className="list-disc pl-5 mb-4">
          <li>
            <strong>Purchase Information</strong> (Used for Developer’s
            Advertising or Marketing, Analytics, App Functionality; Linked to
            your identity; Used for tracking purposes)
          </li>
        </ul>

        <h3 className="text-lg font-semibold mb-1">1.5 Usage Data</h3>
        <ul className="list-disc pl-5 mb-4">
          <li>
            <strong>Product Interaction</strong> (Used for Analytics, Product
            Personalization, App Functionality; Linked to your identity; Used
            for tracking purposes)
          </li>
          <li>
            <strong>Other Usage Data</strong> (Used for App Functionality,
            Product Personalization, Analytics)
          </li>
        </ul>

        <h3 className="text-lg font-semibold mb-1">1.6 Diagnostics</h3>
        <ul className="list-disc pl-5 mb-4">
          <li>
            <strong>Crash Data</strong> (Used for Product Personalization,
            Analytics, App Functionality; Linked to your identity; Used for
            tracking purposes)
          </li>
          <li>
            <strong>Performance Data</strong> (Used for Analytics, App
            Functionality, Product Personalization)
          </li>
          <li>
            <strong>Other Diagnostic Data</strong> (Used for Product
            Personalization, Analytics, App Functionality)
          </li>
        </ul>

        <h2 className="text-xl font-semibold mb-2">
          2. How We Use Your Information
        </h2>
        <ul className="list-disc pl-5 mb-4">
          <li>Providing and maintaining the App</li>
          <li>Improving and personalizing user experiences</li>
          <li>Analyzing usage and trends to enhance features</li>
          <li>Communicating with you about updates or new features</li>
        </ul>

        <h2 className="text-xl font-semibold mb-2">3. Tracking Technologies</h2>
        <p className="mb-4">
          We may use cookies and similar technologies for analytics,
          personalization, and marketing. Where required by applicable law, we
          will seek your consent before using non-essential tracking
          technologies.
        </p>

        <h2 className="text-xl font-semibold mb-2">
          4. Sharing of Your Information
        </h2>
        <ul className="list-disc pl-5 mb-4">
          <li>
            <strong>Service Providers:</strong> with third-party vendors on our
            behalf
          </li>
          <li>
            <strong>Legal Requirements:</strong> when required by law or
            government authorities
          </li>
          <li>
            <strong>Business Transfers:</strong> in connection with a merger or
            acquisition
          </li>
        </ul>

        <h2 className="text-xl font-semibold mb-2">5. Data Security</h2>
        <p className="mb-4">
          We use commercially reasonable safeguards to protect your data, but
          cannot guarantee absolute security.
        </p>

        <h2 className="text-xl font-semibold mb-2">6. Retention of Data</h2>
        <p className="mb-4">
          Personal data is retained only as long as necessary for the purposes
          stated in this Policy, unless a longer retention period is required by
          law.
        </p>

        <h2 className="text-xl font-semibold mb-2">
          7. Your Rights and Choices
        </h2>
        <p className="mb-4">
          Depending on your jurisdiction, you may have rights to access,
          correct, delete, or object to the processing of your personal data.
        </p>

        <h2 className="text-xl font-semibold mb-2">8. Children’s Privacy</h2>
        <p className="mb-4">
          Our App is not intended for individuals under the age of 13 (or the
          minimum age in your jurisdiction). We do not knowingly collect data
          from children.
        </p>

        <h2 className="text-xl font-semibold mb-2">
          9. Changes to This Policy
        </h2>
        <p className="mb-4">
          We may update this Privacy Policy. Any significant changes will be
          posted here, and the updated Policy will be effective upon posting.
        </p>

        <h2 className="text-xl font-semibold mb-2">10. Contact Us</h2>
        <p className="mb-4">
          If you have any questions or wish to exercise any rights related to
          your personal data, please contact us at:
          <br />
          <strong>Email:</strong> info@youwo.ai
        </p>

        <p className="italic text-sm">
          Disclaimer: This Privacy Policy is for informational purposes only and
          does not constitute legal advice. Consult a legal professional to
          ensure compliance with all relevant laws and regulations (e.g.,
          Apple’s App Store guidelines, GDPR, CCPA).
        </p>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
