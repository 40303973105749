import { useEffect } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import ResetPasswordEmail from "./pages/auth/ResetPasswordEmail";
import ResetPasswordSuccess from "./pages/auth/ResetPasswordSuccess";
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import VerifyEmail from "./pages/auth/VerifyEmail";
import LandingPage from "./pages/LandingPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import { injectStore } from "./redux/api";
import { fetchUser } from "./redux/features/user/userSlice";
import store, { AppDispatch, RootState } from "./redux/store";
function AppContent() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
  const userRole = useSelector((state: RootState) => state.user.user?.role);
  const user = useSelector((state: RootState) => state.user.user);
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    console.log("isLoggedIn", isLoggedIn);
    if (isLoggedIn) {
      dispatch(fetchUser());
    }
  }, [isLoggedIn, dispatch, userRole]);

  return (
    <Routes>
      {!isLoggedIn ? (
        <>
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/landing-page" element={<LandingPage />} />
          {/* <Route path="/sign-in" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUp />} /> */}
          {/* <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/forgot-password" element={<ForgotPassword />} /> */}
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route
            path="/reset-password-success"
            element={<ResetPasswordSuccess />}
          />
          {/* <Route
            path="/reset-password-email"
            element={<ResetPasswordEmail />}
          /> */}
          <Route path="*" element={<Navigate to="landing-page" />} />
        </>
      ) : (
        // temp holder
        <>
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/landing-page" element={<LandingPage />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route
            path="/reset-password-email"
            element={<ResetPasswordEmail />}
          />
          <Route path="*" element={<Navigate to="landing-page" />} />
        </>
        // renderRoutesForRole(userRole)
      )}
    </Routes>
  );
}

injectStore(store);

const App = () => {
  return (
    <Provider store={store}>
      <AppContent />
    </Provider>
  );
};

export default App;
