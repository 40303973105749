import { FunctionComponent } from "react";

export type Section2Type = {
  className?: string;
};

const Section2: FunctionComponent<Section2Type> = ({ className = "" }) => {
  return (
    <section
      className={`
        self-stretch bg-whitesmoke overflow-hidden
        flex flex-col lg:flex-row items-center justify-center
        gap-8 px-6 py-12
        max-w-full text-left text-xl text-black font-inter
        ${className}
      `}
    >
      {/* LEFT BLOCK */}
      <div
        className={`
          flex-1 flex flex-col justify-center
          py-[68.5px] px-[50px] box-border gap-5
          max-w-[640px]
          lg:items-end lg:text-left
          items-center text-center
          mq450:py-[29px]

        `}
      >
        <div className="w-full text-xl text-cornflowerblue font-medium lg:text-left text-center">
          AI Boosted
        </div>
        <h1
          className="
            m-0 w-full max-w-[639px] text-41xl font-semibold leading-tight
            mq450:text-29xl
          "
        >
          Unleash the Power of AI: Customized Learning at Its Finest!
        </h1>
        <p
          className="
          w-full max-w-[610px] text-lg
          text-lg
            text-left
          "
        >
          Step into the future of personalized education with YouWo‘s advanced
          AI features. Powered by the latest GPT-4 technology and our own
          customized GPT models, our app is designed to revolutionize the way
          you summarize and comprehend your study material. Our intelligent
          system adapts to your unique learning needs, offering tailored
          summaries and insights that resonate with your personal study habits.
          Whether it’s distilling complex lectures into digestible notes or
          generating creative ideas to explore topics further, our AI does more
          than just summarize—it transforms your educational experience.
          With YouWo, prepare to meet a study assistant that’s as dedicated and
          innovative as you are.
        </p>
      </div>

      {/* RIGHT BLOCK (IMAGE) */}
      <div
        className="
          flex-1 flex items-center justify-start
          max-w-[545px]
        "
      >
        <img
          className="
            w-full max-w-full h-auto object-cover
          "
          loading="lazy"
          alt="AI Boosted Visual"
          src="/landing/section-2.svg"
        />
      </div>
    </section>
  );
};

export default Section2;
